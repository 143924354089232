import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '.';

export function LogOut() {
    const navigate = useNavigate();

    useEffect(() => {
      sessionStorage.clear();      
      navigate('/login');      
    }, []);

    return <LoadingSpinner />;    
  };