import { useState } from 'react';
import config from '../../config';
import { authHeader } from '../../Helpers/auth-header';
import { Loading } from '../../Components';
import { toast } from "react-toastify";

export function ServiceStatusCardComponent({uniqueName, label}) {
    const [status, setStatus] = useState('Unknown'); 
    const [organizationId, setOrganizationId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    if(!organizationId) {
        setOrganizationId(JSON?.parse(window.sessionStorage.getItem('user'))?.id);
    }

    const checkServiceStatus = async() => {
        setIsLoading(true);
        const statusRequestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json', ...authHeader() }
        };
        const response = await fetch(`${config.apiUrl}/api/microservice/status?uniqueName=${uniqueName}`, statusRequestOptions );
        const responseJson = await response.json();
        if( ['Unknown','Deploying','Building'].includes(responseJson.serviceStatusResult) ) {
            toast.info(`${label} status: ${responseJson.serviceStatusResult}`);
        } else if ( responseJson.serviceStatusResult.endsWith('Running') ) {
            toast.success(`${label} is running!`);
        } else {
            toast.warning(`${label} is offline.`);
        }
        setIsLoading(false);
        setStatus(responseJson.serviceStatusResult);
    }

    const startService = async() => {
        setIsLoading(true);
        const startupRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
            body: JSON.stringify({
                uniqueName: uniqueName
            })
        };
        let result = await fetch(`${config.apiUrl}/api/microservice/startup`, startupRequestOptions );
        if(result.status === 200) {
            toast.success(`${label} is starting up!`);
        } else {
            let resultJson = await result.json();
            toast.error(
                `Error starting up ${label}.
                Please provide the following information to Arcane Support: ${resultJson.errorMessage}`,
                {autoClose: false}
            );
        }
        setIsLoading(false);
        return result;
    }

    return (<>
        {isLoading && <Loading/>}
        <div className="col-md-6 col-md-offset-3 ">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{label}</h5>
                    <div className="card-text"><b>Status: </b><div className={status === 'Unknown' || status === 'Deploying' || status === 'Building' ? 'text-muted' : (status.endsWith('Running') ? 'text-success' : 'text-danger')}>{status}</div></div>
                    <button type="button" className="btn btn-primary" onClick={checkServiceStatus} style={{marginRight: '5px'}}>Get Status</button>
                    <button type="button" className="btn btn-primary" onClick={startService}>Start Service</button>
                </div>
            </div>
        </div>
    </>);
}