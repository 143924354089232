import './App.css';
import { Layout } from './Pages/Layout.jsx';
import { HomePage } from './Pages/HomePage';
import { LoginPage } from './Pages/LoginPage';
import { RegistrationPage } from './Pages/RegistrationPage';
import { ProfilePage } from './Pages/ProfilePage';
import { ConfigureSourcePage } from './Pages/ConfigureSourcePage';
import { ConfigureTargetPage } from './Pages/ConfigureTargetPage';
import { DashboardPage } from './Pages/DashboardPage';
import { NotFound } from './Pages/NotFound.jsx';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { LogOut } from './Components';

const PrivateRoute = ({ children }) => {
  return sessionStorage.getItem( 'user' ) ? children : <Navigate to="/login" />;
};

function App() {

  return (
    <div className="container">
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="registration" element={<RegistrationPage />} />
            <Route 
              path="profile" 
              element={
                <PrivateRoute>
                  <ProfilePage/>
                </PrivateRoute>
              } 
            >
              <Route path="configureSource" element={<ConfigureSourcePage />} />
              <Route path="configureTarget" element={<ConfigureTargetPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
            </Route>
          </Route>
          <Route path="logout" element={<LogOut />}></Route>
        </Routes>
      </BrowserRouter>                    
    </div>          
  );
}

export default App
