import { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

// '../../' is the path to src/
import config from '../../config';
import { FormInputComponent } from '../../Components/FormInputComponent';
import FormComponentController from './FormComponentController';

export function FormComponent( { title, handleSubmit, fields, navigateToOnComplete, navigateToOnCancel = '/', withReCaptcha } ) {

    const navigate = useNavigate();
    let inputStateObj = {};
    for( let thisField of fields ) {
        inputStateObj = {
            ...inputStateObj,
            [thisField.uniqueName]: ''
        };
    }

    let initializedStateObj = {};
    for( let thisField of fields ) {
        initializedStateObj = {
            ...initializedStateObj,
            [thisField.uniqueName]: false
        };
    }
    const [input, setInput] = useState( {...inputStateObj} );
    const [initialized] = useState({...initializedStateObj});    
    const [error, setError] = useState( {} );
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    
    const controller = new FormComponentController( input, setInput, setLoading, error, setError, setErrorMessage, navigate, fields );    

    const callHandleSubmit = (e) => {  
                       
        if( withReCaptcha && !input.token ) { 
            setErrorMessage('reCapcha has not been verified');
            e.preventDefault();            
            return false;
        }
        
        controller.handleSubmit(e, handleSubmit, navigateToOnComplete);
    } 

    const onVerify = useCallback((token) => {
        setInput(prev => ({
            ...prev,
            token: token
        }));               
    }, []);    

    return (
        <div className="col-md-6 col-md-offset-3">
            <h2>{title}</h2>
            {errorMessage && (
                <p className="alert alert-danger" role="alert"> {errorMessage} </p>
            )}
            <form name="form" onSubmit={callHandleSubmit}>
                {fields.map((field) => {
                    return (
                        <FormInputComponent 
                            key={field.uniqueName} 
                            input={input} 
                            initialized={initialized}
                            error={error} 
                            controller={controller} 
                            uniqueName={field.uniqueName} 
                            label={field.label} 
                            inputType={field.attributeType} 
                            initialValue={field.defaultValue} 
                            fieldObject={field} 
                        />
                    );
                })}                
                
                { withReCaptcha && <>
                    <div id="recaptcha" className="mb-3"></div>
                    <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaKey} useEnterprise="true" 
                        container={ { element: 'recaptcha', parameters: { theme: 'dark' } } }
                    >
                        <GoogleReCaptcha onVerify={onVerify} />
                    </GoogleReCaptchaProvider>
                  </>
                }

                <button className="btn btn-primary">Submit</button>
                {loading && 
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading"/>
                }      
                <Link to={navigateToOnCancel} className="btn btn-link">Cancel</Link>
            </form>           
        </div>        
      );
}