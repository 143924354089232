export function HomePage() {
    return (
        <div className="jumbotron">
            <h1 className="display-4">Home</h1>            
            <p className="lead">Welcome to Arcane ECM</p>
            <hr className="my-4"></hr>
            <p>Arcane ECM is an Enterprise Content Management solution for Salesforce. Store your files anywhere. View and manage them from Salesforce.
            </p>
            <p className="lead">
                <a className="btn btn-primary btn-lg" href="https://34.48.122.178/?page_id=26#arcane-ecm" role="button">Learn more</a>
            </p>
        </div>
        
    );
}