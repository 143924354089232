import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**  
   we can override standard Bootstrap styling with the customized one. The standard .../bootstrap.min.css needs to be replaced with the commended line below. 
   Use the following command: "sass ./scss/custom.scss ./src/css/custom.css" to regenerate the custom css file
**/

import './css/custom.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
