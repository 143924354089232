import React from "react";
import "./spinner.css";

export function LoadingSpinner({ className }) {
  return (
    <div className={`spinner-container ${className}`}>
      <div className="loading-spinner">
      </div>
    </div>
  );
}