import logo from '../logo.svg';
import { Outlet } from "react-router-dom";
import { NavigationComponent } from '../Components/Navigation/NavigationComponent';

import { ToastContainer } from "react-toastify";	
import "react-toastify/dist/ReactToastify.css";

export function Layout() {
    return (        
        <>  
        <header>     
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid justify-content-start">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="Arcane" width="120" height="50" className="logo"/ >
                    </a>                
                    <NavigationComponent/> 
                </div>
            </nav>                        
        </header>     
        <ToastContainer position="top-center" />   
        <Outlet />        
        <footer className="bg-primary-subtle p-3 mt-3 text-center fixed-bottom">
            Copyright by Arcane LLC.
        </footer>
        </>        
    );
}