import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";

export function NavigationComponent() {

    const [isLoggedIn, setLoggedIn] = useState(false);

    function storageEventHandler() {        
        setLoggedIn( sessionStorage.getItem('user') != null );        
        window.removeEventListener('storage', storageEventHandler, false); // disposing listener immediately, otherwise it might cause prolifiration of event listeners for multple login/logout actions
    }

    useEffect(() => {                
        window.addEventListener('storage', storageEventHandler, false );
        setLoggedIn( sessionStorage.getItem('user') != null );        
    }, []);

    return (
        <ul className="nav nav-underline">                    
            <li className="nav-item">
                <NavLink to="/" className="nav-link">Home</NavLink>                
            </li>
            {isLoggedIn ? (
                <>                    
                    <li className="nav-item">
                        <NavLink to="/profile" className="nav-link">Profile</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/logout" className="nav-link">Logout</NavLink>
                    </li>
                </>
                ) : (
                <>
                    <li className="nav-item">
                        <NavLink to="/login" className="nav-link">Login</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/registration" className="nav-link">Registration</NavLink>
                    </li> 
                </>
                )}
        </ul> 
    );
}